.video-wrapper {
    position: relative;
    display: block;

    * {
        transition: none;
    }

    .overlay {
        position: absolute;
        top: calc(50% - 120px);
        left: 0;
        width: 50%;
        margin: 0 25%;
        padding: 3% 0;
        border-radius: 4px;
        background: rgba(254, 179, 0, 0.9);
        text-align: center;
        font-size: 18px;
        color: #222;

        @media (max-width: 600px) {
            font-size: 12px;
            width: 80%;
            margin: 0 10%;
            top: calc(50% - 80px);
        }

        .fa {
            display: block;
            font-size: 8em;

            @media (max-width: 600px) {
                font-size: 6em;
            }
        }

        .action {
            display: block;
            font-size: 2em;
        }

        .hint {
            display: block;
            font-size: 1em;
        }
    }
}
